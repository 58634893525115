import React, { Component } from 'react';

import Portfolio from '../components/Portfolio Content/Portfolio'

class PortfolioSection extends Component {
  render() {
    return (
      <Portfolio />
    )
  }
}

export default PortfolioSection;